<template>
  <div>
    <b-card>
      <div style="text-align: right;" class="mr-2">
        <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.CustomerAddModal
            variant="primary"
        >
          Müşteri Ekle
        </b-button>
      </div>


      <div class="col-md-4 offset-md-8 mt-2" style="text-align: right;">
        <b-form-group
            label="Ara: "
            label-cols-md="3"
            label-align-md="right"
            label-size="md"
            label-for="filterInput"
            class="mb-0"
        >
          <b-input-group size="md">
            <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder=""
            />
            <b-input-group-append>
              <b-button
                  :disabled="!filter"
                  @click="filter = ''"
              >
                Temizle
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>

      <b-card>
        <b-table
            :striped=true
            :hover=true
            :foot-clone=true
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            class="mb-0"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
        >
          <template #cell(id)="data">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-tooltip.hover.top="'Düzenle'"
                v-b-modal.CustomerEditModal
                variant="success"
                class="btn-icon mr-1"
                @click="customerEdit(data.item.id)"
            >
              <feather-icon icon="EditIcon"/>
            </b-button>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-tooltip.hover.top="'Sil'"
                variant="danger"
                class="btn-icon"
                @click="deleteRow(data.item.id)"
            >
              <feather-icon icon="TrashIcon"/>
            </b-button>
          </template>
        </b-table>
        <div class="mx-2 mb-2 mt-2">
          <b-row>
            <!-- Per Page -->
            <b-col
                cols="6"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <b-form-select
                  v-model="perPage"
                  :options="[10,20,30,40,50]"
                  class="per-page-selector d-inline-block col-sm-2"
              />
              <label class="ml-1">Kayıt göster</label>
            </b-col>

            <!-- Pagination -->
            <b-col
                cols="6"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRow"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

            <b-col
                cols="12"
                sm="12"
                class="mt-2 d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Toplam {{ totalRow }} kayıt bulunmaktadır.</span>
            </b-col>

          </b-row>
        </div>
      </b-card>


      <b-modal
          id="CustomerAddModal"
          ref="addModal"
          centered
          no-close-on-backdrop
          title="Müşteri Ekle"
          hide-footer
      >
        <CustomerAdd @createModal="addModal = $event"></CustomerAdd>
      </b-modal>

      <b-modal
          id="CustomerEditModal"
          ref="editModal"
          centered
          no-close-on-backdrop
          title="Müşteri Düzenle"
          hide-footer
      >
        <CustomerEdit @editModal="editModal = $event" :customerInfo="selectedCustomer"></CustomerEdit>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  VBTooltip,
  BBadge,
  BSpinner,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormGroup,
  VBModal,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BMedia,
  BModal,
  BPagination,
  BRow,
  BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import 'animate.css'
import axios from "axios";
import CustomerAdd from './CustomerAdd.vue'
import CustomerEdit from './CustomerEdit.vue'

export default {
  components: {
    CustomerAdd, CustomerEdit,
    BForm,
    BSpinner,
    BTable,
    BCard,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  }, data() {
    return {
      addModal: false,
      editModal: false,
      selectedCustomer: null,
      perPage: 10,
      totalRow: 0,
      currentPage: 1,
      filter: null,
      filterOn: [],
      fields: [
        {
          key: 'nameSurname',
          label: 'Ad Soyad',
        },
        {
          key: 'phone',
          label: 'Telefon'
        },
        {
          key: 'address',
          label: 'Adres',
        },
        {
          key: 'id',
          label: 'İşlemler',
        }
      ],
      items: [],
    }
  },
  computed: {},
  mounted() {
  },
  created() {
    this.customerGet()
  },
  methods: {
    customerGet() {
      axios.get('/customer/list')
          .then(response => {
            this.items = response.data.data
            this.totalRow = response.data.data === null ? 0 : response.data.data.length
          })
          .catch()
    },
    deleteRow(rowId) {
      this.$swalDeleteAsk()
          .then((result) => {
            if (result) {
              axios.delete('/customer/delete', {
                params: {
                  customerId: rowId
                }
              })
                  .then(response => {
                    this.$toastApiResponse(response.data)
                    if (response.data.status) {
                      this.items = this.items.filter(item => item.id !== rowId)
                    }
                  })
                  .catch()
            }
          })
          .catch((error) => {
            console.error(error)
          })
    },
    customerEdit(rowId) {
      this.selectedCustomer = this.items.find(r => r.id === rowId)
    },
    onFiltered(filteredItems) {
      this.totalRow = filteredItems.length
      this.currentPage = 1
    },
  },
  watch: {
    addModal(yeni, eski) {
      if (yeni) {
        this.customerGet()
        this.$refs.addModal.hide()
        this.addModal = false
      }
    },
    editModal(yeni, eski) {
      if (yeni) {
        this.customerGet()
        this.$refs.editModal.hide()
        this.editModal = false
      }
    },
  }
}
</script>

<style>

</style>
